<template>

<div id="app" :class="{'is-mobile': window.is.mobile, 'is-device': !window.is.desktop, 'is-tablet': window.is.tablet, 'is-desktop': window.is.desktop}">

	<template v-if="$state">

		<app-cta v-if="$state.footer.cta_enabled" />

		<com-head v-on:search="searching = true" />

		<com-search v-if="searching" v-on:close="searching = false" />

		<com-content :loading="loading">
			
			<router-view :key="$path"></router-view>

		</com-content>

		<com-foot />

	</template>

</div>

</template>

<script>

export default {

	name: 'App',

	components: {

		'com-head': () => import('./components/Head'),
		'com-search': () => import('./components/Search'),
		'com-content': () => import('./components/Content'),
		'com-foot': () => import('./components/Foot')

	},

	data: function() {

		return {
			loading: false,
			searching: false
		}

	},

	watch: {

		$path: function() {

			this.searching = false

			this.load()

		}

	},

	created: function() {

		this.load()

	},

	methods: {

		load: function() {

			this.loading = true

			this.$api.get('page', {
				path: this.$route.path
			}).then(function(json) {

				this.$store.commit('set', json)

				document.title = json.meta.title

				document.querySelector('meta[property="og:title"]').setAttribute("content", json.meta.title)
				document.querySelector('meta[name="twitter:title"]').setAttribute("content", json.meta.title)

				document.querySelector('meta[name="description"]').setAttribute("content", json.meta.description)
				document.querySelector('meta[property="og:description"]').setAttribute("content", json.meta.description)
				document.querySelector('meta[name="twitter:description"]').setAttribute("content", json.meta.description)

				document.querySelector('meta[property="og:image"]').setAttribute("content", json.meta.image)
				document.querySelector('meta[name="twitter:image"]').setAttribute("content", json.meta.image)

				window.scrollTo(0, 0)

				// eslint-disable-next-line
				gtag('event', 'page_view', {
					"page_title": json.meta.title,
				})

				this.loading = false

			}.bind(this), function() {

				this.$router.push({
					name: 'Notfound'
				})

			}.bind(this))

		}

	}

}

</script>

<style>

#app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

</style>
